<template>
  <div class="title" id="topId">
    <div class="title-box">
      <div class="product-title bg-cover" v-html="item.title"></div>
      <div class="title-flex" ref="activeClick">
        <div
          v-for="(item, key) in item.list"
          :key="key"
          :data-index="item.index"
          :class="{ active: titleActive == item.index }"
          @click="activeClick(item.index)"
        >
          <span class="yichu1" v-html="item.detail" :title="item.detail"
            ></span
          >
        </div>
        <!-- <div data-index="2" :class="{ active: this.titleActive == 2 }">
              <span>裸眼3D拼接屏</span>
            </div>
            <div data-index="3" :class="{ active: this.titleActive == 3 }">
               <span>裸眼3D智能膜</span>
            </div>
            <div data-index="4" :class="{ active: this.titleActive == 4 }">
              <span>裸眼3D眼镜</span>
            </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    defaultItem: {
      type: String,
      default() {
        return this.$route.path || "1";
      },
    },
    item: {
      // 头部内容
      type: Object,
      default() {
        return {
          title: "产品中心",
          list: [
            { index: "1", detail: "裸眼3D显示屏" },
            { index: "2", detail: "裸眼3D显示屏" },
            { index: "3", detail: "裸眼3D显示屏" },
            { index: "4", detail: "裸眼3D显示屏" },
          ],
        };
      },
    },
    titleActive: {
      // 默认选中第一个item
      type: [String,Number],
      default() {
        return "1";
      },
    },
        toRoute: {
      // 默认选中第一个item
      type: [String],
      default() {
        return "/home";
      },
    },
  },
  data() {
    return {
      //用于路由跳转
      thisRoute: this.$route.path,
      //去url的id值，或默认为 1
      newActive: "1",
    };
  },
  methods: {
    activeClick(index) {
      //切换，index,然后根据传入id跳转到指定路由
        console.log( this.toRoute);
      this.newActive = index;
      this.$router.push({ path: this.toRoute + "?id=" + this.newActive });
    },
  },
  mounted() {

    // console.log(this.$route.path);
  },
  watch: {
    $route(to, from) {
      this.newActive = to.query.id;
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  // border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background: #f9f9f9;
  line-height: 50px;
  min-height: 52px;
  box-sizing: border-box;
  .title-box {
    padding: 0 2%;
    max-width: 1400px;

    display: flex;
    .product-title {
      width: 180px;
      line-height: 80px;
      background: #0068b7;
      font-size: 24px;
      color: #fff;
      text-align: center;
      position: relative;
      z-index: 1;
      margin-top: -29px;
      border-radius: 50px 0 0px 0;
      background-image: url(../../assets/img/Menu002169-columnbg.png);
                &::before{
            display: inline-block;
    content: '';
    width: 15px;
    height: 3px;
    background: #fff;
    margin-right: 5px;
    vertical-align: middle;
          }
    }
  }
  .title-flex {
    display: flex;
    margin-left: 20px;

    > div {
      max-width: 140px;
      display: block;
      padding: 0 12px;
      position: relative;
      height: 50px;
      color: #333;
      text-decoration: none;
      cursor: pointer;
    }
    span {
      display: block;
      width: 100%;
      height: 100%;
    }
    .active {
      font-weight: bold;
      color: #0068b7;
      span::after {
        //下划线
        background: #0068b7;
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        content: "";
        bottom: -1px;
      }
      &::after {
        // background: #0068b7;
        //倒三角
        display: block;
        width: 0;
        height: 0;
        margin: 0 auto;
        content: "";
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #0068b7;
      }
    }
  }
}

@media (max-width: 767px) {
  .about-box {
    .title {
      // max-height: auto!important;
      .title-box {
        padding: 0;
        flex-wrap: wrap;
        .product-title {
          width: 100%;
          border-radius: 0;
          margin-top: -1px;
          line-height: 40px;
          // display: none;
        }
      }
      .title-flex {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }
  }
}
</style>
