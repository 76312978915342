<template>
  <div class="about">
    <header-img :bgImg="require('@/assets/img/concat/Banner002187-ban7_wap.jpg')"></header-img>

    <!-- <concat></concat> -->
    <div class="about-box">
      <item-contorl :item="item" :titleActive="titleActive"></item-contorl>
      <ConcatInfo></ConcatInfo>
    </div>
  </div>
</template>
<script>
import headerImg from "@/components/CommonConponent/headerImg.vue";
import ConcatInfo from "@/components/concatinfo/concatinfo.vue";
import ItemContorl from "../components/CommonConponent/itemContorl.vue";

export default {
  name: "concat",
  components: {
    headerImg,
    ConcatInfo,
    ItemContorl,
  },
  data() {
    return {
      //去url的id值，或默认为 1
      titleActive: this.$route.query.id || '1',
      item: {
        title: "Contact",
        list: [
          { index: "1", detail: "Contact information" },
         
        ],
      },
    };
  },
  methods: {
    // activeClick(e) {
    //   //切换，index
    //   this.titleActive = e.target.parentElement.dataset.index;
    // },
  },
  mounted() {
    console.log("title" + this.titleActive);
  },
   watch:{
    $route(to,from){
      this.titleActive = to.query.id
    }
}
};
</script>
<style lang="less" scoped>
.about-box {
  min-height: 300px;

  .detail-box {
    padding: 3% 0;
    .detail-container {
      // padding: 0 2%;
      max-width: 1400px;
      margin: 0 auto;
      > div {
        // margin-bottom: 30px;
        height: 427.6px;
        display: block;
        padding: 15px;
        height: 100%;
      }

      .detail-more {
        position: relative;
        background: #f8f8f8;
        padding: 15px;
        height: 100%;
        text-align: left;
        .hover-more {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: rgba(0, 104, 183, 0.9);
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
          i {
            color: #fff;
            position: absolute;
            width: 100%;
            background: url("~@/assets/img/product/Floor002048-detail.png")
              no-repeat center top;
            padding-top: 40px;
            line-height: 20px;
            top: 50%;
            margin-top: -30px;
            text-align: center;
            font-style: normal;
          }
        }
        .detail-more-img {
          width: 100%;
          height: 334px;
          margin-bottom: 10px;
          background-size: contain;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      h5 {
        margin: 0;
        font-size: 18px;
        font-weight: normal;
        line-height: 30px;
        text-transform: uppercase;
        font-family: Arial, Helvetica, sans-serif;
        color: #333;
        display: block;
      }
      span {
        color: #8a8fa0;
        line-height: 24px;
        font-size: 12px;
      }
    }
  }
}
</style>
