<template>
  <div class="form-info">
    <div class="auto">
      <div class="info-left">
        <div>
          <i><img src="@/assets/img/concatinfo/add.png" alt="" /></i>
          <h3>
            {{$t("concat.mailbox")}}
            <span> info@smartsuperv.com </span>
          </h3>
        </div>
        <div>
          <i><img src="@/assets/img/concatinfo/add.png" alt="" /></i>
          <h3>
            {{$t("concat.FactoryAddress")}}
            <span>
            {{$t("concat.FactoryAddressDetail")}}
               </span>
          </h3>
        </div>
        <div>
          <i><img src="@/assets/img/concatinfo/add.png" alt="" /></i>
          <h3>
            {{$t("concat.OfficeAddress")}}
            <span>
                 {{$t("concat.OfficeAddressDetail")}}
            </span>
          </h3>
        </div>
      </div>
      <form id="whir_1" res="whirform">
        <dl class="odd">
          <dt><em>*</em> {{$t("concat.name")}}：</dt>
          <dd>
            <input
              id="17862"
              name="17862"
              type="text"
              class="text"
              maxlength="512"
              value=""
              placeholder="Your name/company name"
            />
            <span
              name="whirValidator"
              validatorfor="17862"
              required="True"
              requiredmsg="您的名字/公司名称为必填"
              regexp=""
              errmsg="您的名字/公司名称验证不通过"
            ></span>
          </dd>
        </dl>
        <dl class="odd">
          <dt><em>*</em> {{$t("concat.number")}}：</dt>
          <dd>
            <input
              id="17863"
              name="17863"
              type="text"
              placeholder="Your phone/landline number"
              class="text"
              maxlength="250"
              value=""
            />
            <span
              name="whirValidator"
              validatorfor="17863"
              required="True"
              requiredmsg="您的电话/座机号码为必填"
              regexp="(^([0-9]{4}-[0-9]{8})|([0-9]{3}-[0-9]{8})|([0-9]{4}-[0-9]{7})$)|(^(1[3|4|5|7|8])(\d){9}$)"
              errmsg="您的电话/座机号码验证不通过"
            ></span>
          </dd>
        </dl>
        <dl class="odd">
          <dt><em>*</em> {{$t("concat.EMail")}}：</dt>
          <dd>
            <input
              id="17864"
              name="17864"
              type="text"
              class="text"
              maxlength="250"
              value=""
              placeholder="E.g：example@163.com"
            />
            <span
              name="whirValidator"
              validatorfor="17864"
              required="True"
              requiredmsg="电子邮箱为必填"
              regexp="^\w+([-+.]\w+)*@\w+([-.]\\w+)*\.\w+([-.]\w+)*$"
              errmsg="电子邮箱验证不通过"
            ></span>
          </dd>
        </dl>
        <div class="clear"></div>
        <dl class="dl">
          <dt><em>*</em> {{$t("concat.massage")}}：</dt>
          <dd>
            <textarea
              id="17867"
              name="17867"
              rows="2"
              cols="20"
              placeholder="Please fill in the content you want to consult"
              maxlength="1024"
              class="text"
            ></textarea>
            <span
              name="whirValidator"
              validatorfor="17867"
              required="True"
              requiredmsg="留言内容为必填"
              regexp=""
              errmsg="留言内容验证不通过"
            ></span>
          </dd>
        </dl>
        <dl class="dl submitbin">
          <dt></dt>
          <dd class="clearfix">
            <input
              name=""
              type="button"
              class="btn submitbtn"
              @click="infoSubmit"
              value="Commit"
            />
            <input
              name=""
              type="button"
              class="btn"
              @click="infoSubmit"
              value="Reset"
            />
          </dd>
        </dl>
      </form>
    </div>
    <div class="map">
      <baidu-map ref="mapBox" ak='Q8TmGSxrmOxzLZk6ja2SBY1qPPRL2laM' :scroll-wheel-zoom="true" id="allmap" @ready="mapReady">
        <bm-marker
          :position="{lng: NowPositon.x, lat: NowPositon.y}"
          
          animation="BMAP_ANIMATION_BOUNCE"
        ></bm-marker>
        <bm-info-window :width="width"  :position="{lng: NowPositon.x, lat: NowPositon.y}" style="padding:0 20px"  :show="infoWindow.show"  >
             <p>{{NowPositon.address}}</p>
       </bm-info-window>

      </baidu-map>
      <div class="mapleft">
        <ul class="ul">
          <li
            data-x="114.011276"
            data-y="22.600125"
            data-add="$t('concat.OfficeAddress'):   $t('concat.FactoryAddressDetail')"
            :class="{on:activeMap==1}"
            @click="activeClick(1)"
          >
            <div>
              <h5>
                   {{$t("concat.OfficeAddress")}}:   {{$t("concat.OfficeAddressDetail")}}
                <!-- 办公地址：深圳市南山区桃源街道长源社区学苑大道1001号南山智园A7栋801 -->
              </h5>
            </div>
          </li>

          <li
            data-x="113.918665"
            data-y="22.735045"
            data-add="工厂地址：深圳市光明高新技术产业园西区同观路转二号路华力特科技园生产楼四层"
             :class="{on:activeMap==2}"
              @click="activeClick(2)"
          >
            <div>
              <h5>
                 {{$t("concat.FactoryAddress")}}:   {{$t("concat.FactoryAddressDetail")}}
                <!-- 工厂地址：深圳市光明高新技术产业园西区同观路转二号路华力特科技园生产楼四层 -->
              </h5>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
//百度地图按需引入
import {BaiduMap,BmMarker, BmInfoWindow,} from 'vue-baidu-map'
export default {
  components:{
    BaiduMap,BmMarker ,BmInfoWindow
  },
  data() {
    return {
      point: "",
      //1=>南山  2=>光明
      width:100,
      activeMap:1,
      NowPositon:{ x: 114.011276, y: 22.600125,address:this.$t("concat.OfficeAddressDetail") },//默认南山
      NanshangInt: { x: 114.011276, y: 22.600125,address:this.$t("concat.OfficeAddressDetail") }, //南山经纬度
      guangmingInt: { x: 113.918665, y: 22.735045,address:this.$t("concat.FactoryAddressDetail") }, //光明经纬度
      infoWindow: {
        show: true,
        contents:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
    };
  },
  methods: {
    infoSubmit(sub) {
      this.$message({
        message: 'success',
        type: 'success'
      })
    },
    mapReady({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(this.NowPositon.x, this.NowPositon.y);
      map.centerAndZoom(this.point, 12);
    },
    activeClick(index){
      this.activeMap = index;
      //切换中心坐标，在南山/光明之间切换
      if(index==1){ //南山
        this.NowPositon = this.NanshangInt
      }else{
         this.NowPositon = this.guangmingInt 
      }
      //移动到当前坐标的中心点
      this.point = new BMap.Point(this.NowPositon.x, this.NowPositon.y);
      this.$refs.mapBox.map.panTo(this.point,true)
    },

  },
};
</script>

<style lang="less" scoped>
.form-info {
  padding: 3% 2%;
  .auto {
    position: relative;
    .info-left {
      width: 38%;
      margin-right: 3%;
      > div {
        text-align: left;
        font-weight: 600;
        background: #f8f8f8;
        padding: 5% 6% 5% 106px;
        margin-bottom: 4%;
        position: relative;
        i {
          display: block;
          width: 64px;
          height: 64px;
          position: absolute;
          left: 5%;
          img {
            display: block;
            height: auto;
            display: block;
            border: none;
            max-width: 100%;
            vertical-align: middle;
          }
        }
        h3 {
          color: #333;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
          span {
            font-size: 24px;
            color: #333;
            display: block;
            margin-top: 10px;
            line-height: 32px;
            word-wrap:break-word
          }
        }
      }
    }
    form {
      width: 60%;
      margin-right: 0;
      position: absolute;
      top: 0;
      left: 40%;
    }
    dl {
      padding-left: 165px;
      min-height: 40px;
      position: relative;
      margin-bottom: 20px;
    }
    .odd {
      width: 100%;
    }

    dt {
      color: #333;
      font-size: 14px;
      position: absolute;
      left: 0;
      width: 165px;
      text-align: right;
      line-height: 40px;
    }
    dt em {
      color: #f00;
      margin-right: 5px;
      font-style: normal;
    }
    .text {
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      width: 100%;
      padding: 0 10px;
      line-height: 38px;
      color: #333;
      min-height: 40px;
    }
    .text.placeholder {
      color: #aaa;
    }
    dl.dl {
      width: 100%;
      float: none;
      max-width: none;
    }
    textarea {
      border: 1px solid #dfdfdf;
      border-radius: 5px;
      overflow: hidden;
      resize: none;
      width: 100%;
      height: 220px;
      padding: 10px;
      color: #5b5b5b;
    }
    .btn {
      display: inline-block;
      line-height: 40px;
      min-width: 140px;
      margin-right: 20px;
      background-color: #f3f3f3;
      color: #728391;
      font-size: 14px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    .submitbtn {
      color: #fff;
      background-color: #213c71;
    }
  }
  .map {
    margin-top: 2%;
    padding-bottom: 4%;
    display: flex;
    #allmap {
      height: 350px;
      width: 70%;
      margin: 0 auto;
    }
    .mapleft {
      width: 25.8%;
      height: 350px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: left;
        .on {
          div {
            border-left-color: #213c71;
          }
        }
        li {
          margin-bottom: 8%;
          cursor: pointer;
          list-style: none;
          div {
            border-left: 5px #b6b6b6 solid;
            background-color: #fff;
            padding: 15px;
            margin: 0;
          }

          h5 {
            font-weight: normal;
            height: 48px;
            margin: 0;
            font-size: 14px;
            line-height: 24px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
@media (max-width: 767px){
  .form-info{
    .auto{
      .info-left{
        width: 100%;
        >div{
          h3{
            span{
              font-size: 16px;
            }
          }
        }
      }
      form{
        position: initial;
        padding: 0 30px;
        width: 100%;
      }
      
        .submitbin{
          padding: 0;
        }
      
      .btn{
        min-width: 80px;
      }
    }
  }
}
@media (max-width: 391px){
    .form-info{
      .map{
        flex-direction: column;
        padding-bottom: 0;
        #allmap{
          width: 100%;
        }
        .mapleft{
          width: 100%;
          height: auto;
          margin-top: 10px;
          ul{
            li{
              &:first-child{
                 margin-bottom: 10px;
              }
             
            }
          }
          // ul{
          //   display: flex;
          //   justify-content: space-evenly;                
          //   .on {
          //       div {
          //         border-bottom-color: #213c71;
          //       }
          //     }
          //   li{
          //     width: 45%;
          //     margin-bottom: 0;

          //     div{
          //       border-left: 0;
          //       border-bottom: 5px #b6b6b6 solid;
                
          //     }
          //     h5{
          //       height: auto;
          //     }
          //   }
          // }
        }
      }

    .auto{
      form{
        padding:  0 ;
      }
    }}
}
</style>>
